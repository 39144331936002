import * as React from 'react'

import * as Yup from 'yup'

import * as e from '@fe/components/Elements'
import * as Form from '@fe/components/Form'
import emailValidation from '@fe/components/common/validation/email'
import useForgotPassword from '@fe/hooks/Auth/useForgotPassword'

import useAuthService from '../../../hooks/Auth/useService'

import * as c from './components'

const ForgotSchema = Yup.object().shape({
  email: emailValidation,
})

export default function ForgotForm() {
  const { isLoading, isFailed, setIsFailed, forgot } = useForgotPassword()
  const { openAuthModal } = useAuthService()

  const [messageToDisplay, setMessageToDisplay] = React.useState('')
  React.useEffect(() => {
    if (isFailed) {
      setMessageToDisplay('forgot_failed')
    }
  }, [isFailed, setMessageToDisplay])

  const renderMessage = React.useCallback(() => {
    switch (messageToDisplay) {
      case 'forgot_success':
        return (
          <c.SuccessMessage>
            <div style={{ color: 'green' }}>
              <e.Icon.Tick />
            </div>
            <e.Text.Regular color='green'>
              We have sent an email to that address with a password reset.
              Unsure if that was the correct email address? Email us at{' '}
              <e.Link.Basic to='mailto:kitchen@allplants.com'>
                kitchen@allplants.com
              </e.Link.Basic>
            </e.Text.Regular>
          </c.SuccessMessage>
        )
      case 'forgot_failed':
        return (
          <c.SuccessMessage>
            <div style={{ color: 'red' }}>
              <e.Icon.Cross />
            </div>
            <e.Text.Regular color='red'>
              There has been a problem sending a password reset link to your
              email, please try again.
            </e.Text.Regular>
          </c.SuccessMessage>
        )
      default:
        throw new Error(`unknown case ${messageToDisplay}`)
    }
  }, [messageToDisplay])

  return (
    <e.Loading.Contained isLoading={isLoading} message='sending you an email'>
      {messageToDisplay === 'forgot_success' ? (
        renderMessage()
      ) : (
        <>
          <c.IntroContainer>
            <e.Text.FormTitleText>
              <strong>forgotten your password?</strong>
            </e.Text.FormTitleText>
            {messageToDisplay === 'forgot_failed' && renderMessage()}
            <e.Text.PSmall>
              if you’ve already created a password, we'll send you an email to
              allow you to reset
            </e.Text.PSmall>
          </c.IntroContainer>
          <Form.Container
            initialValues={{}}
            name='reset'
            onSubmit={async ({ email }) => {
              setMessageToDisplay('')
              setIsFailed(false)
              await forgot(email)
              setMessageToDisplay('forgot_success')
            }}
            validationSchema={ForgotSchema}
          >
            {() => (
              <>
                <Form.Row>
                  <Form.Input
                    autoFocus
                    label='email'
                    name='email'
                    type='email'
                  />
                </Form.Row>
                <e.Container.RowCenterJustified>
                  <e.Button.default isBlock isCondensed type='submit' useSubmit>
                    Next
                  </e.Button.default>
                </e.Container.RowCenterJustified>
              </>
            )}
          </Form.Container>
          <e.Container.RowCenterJustified>
            <c.BottomLink>
              <e.Button.Text
                id='login'
                onClick={() => openAuthModal('login')}
                type='button'
              >
                back to login
              </e.Button.Text>
            </c.BottomLink>
          </e.Container.RowCenterJustified>
        </>
      )}
    </e.Loading.Contained>
  )
}
