import * as React from 'react'

import * as safeAccess from 'safe-access'

import * as allplantsAuth from '@fe/services/allplantsAuth'
import { trackEvent } from '@fe/services/analytics'

export default function useResetPassword() {
  const [isLoading, setIsLoading] = React.useState(false)
  const [isFailed, setIsFailed] = React.useState(false)

  const reset = async (password: string, token: string) => {
    try {
      setIsLoading(true)
      setIsFailed(false)
      await allplantsAuth.resetPassword(password, token)
      void trackEvent('auth.reset.success')
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      setIsFailed(true)
      void trackEvent('auth.reset.fail', {
        error: `${e}`,
        nestedError: JSON.stringify(safeAccess(e, 'response.data')),
      })
      return Promise.reject(e)
    }
  }

  return {
    isLoading,
    isFailed,
    setIsFailed,
    reset,
  }
}
