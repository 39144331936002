import styled from '@fe/styles'

export const Container = styled.div`
  background: white;
  ${(p) => p.theme.layout.paddingResizeCss};
  max-width: 300px;
  width: 100%;
`

export const SuccessMessage = styled.div`
  padding: 30px;
  text-align: center;
`
export const IntroContainer = styled.div`
  text-align: left;
  padding-bottom: ${(p) => p.theme.layout.spacingVertical};
`

export const ResetPageIntro = styled.div`
  padding-bottom: 25px;
`

export const BottomLink = styled.div`
  margin: 10px;
`

export const ForgotPasswordLink = styled.div`
  text-align: left;
  padding: 5px 0;
`
