import * as React from 'react'

import { toast } from 'react-hot-toast'
import * as Yup from 'yup'

import * as e from '@fe/components/Elements'
import Toast from '@fe/components/Elements/toast'
import * as Form from '@fe/components/Form'
import { useDiscountManager } from '@fe/components/checkout/DiscountInput'
import useTryApplyDiscount from '@fe/components/checkout/DiscountInput/useTryDiscount'
import emailValidation from '@fe/components/common/validation/email'
import useCart from '@fe/providers/cart'

import useLogin from '../../../hooks/Auth/useLogin'
import useAuthService from '../../../hooks/Auth/useService'

import * as c from './components'

const LoginSchema = Yup.object().shape({
  email: emailValidation,
  password: Yup.string()
    .min(4, 'please enter your full password')
    .required('please enter your password')
    .ensure(),
})

export default function LoginForm() {
  const [isLoading, setIsLoading] = React.useState(false)
  const [loadingMessage, setLoadingMessage] = React.useState('logging you in')
  const [isFailed, setIsFailed] = React.useState(false)
  const { login } = useLogin()
  const { cart, getCart } = useCart()
  const { tryApplyDiscount } = useTryApplyDiscount()
  const { setSuccessMessage } = useDiscountManager()
  const { openAuthModal } = useAuthService()

  const postLoginActions = async () => {
    setSuccessMessage('')
    const discountCodeInCart = cart?.discountCodes?.[0]?.code

    if (discountCodeInCart && cart?.token) {
      try {
        // Reapply the same discount to our cart so that we can check that the
        // discount code is valid against this logged in user.
        await tryApplyDiscount(cart.token, discountCodeInCart, cart?.id)
      } catch (err) {
        await getCart(cart.token) // Get the latest cart (updating the UI)
        const errorType = err.response?.data?.errors?.[0]?.error_code
        if (errorType === 'discount_new_customers_only') {
          toast.custom(
            <Toast
              message='Sorry, the code won’t work with this order.'
              type='error'
            />,
            {
              duration: Infinity,
            }
          )
        }
      }
    }
  }

  return (
    <e.Loading.Contained isLoading={isLoading} message={loadingMessage}>
      <c.IntroContainer>
        <e.Text.FormTitleText>
          <strong>log in</strong>
        </e.Text.FormTitleText>
        <e.Text.PSmall>
          log in to change your dishes, update your order or redeem rewards
        </e.Text.PSmall>
      </c.IntroContainer>
      <Form.Container
        initialValues={{}}
        name='login'
        onSubmit={async (data) => {
          setIsFailed(false)
          setLoadingMessage('logging you in')
          setIsLoading(true)
          try {
            await login(data)
            openAuthModal()
            setLoadingMessage('logged in successfully.')
            postLoginActions()
          } catch (err) {
            setIsLoading(false)
            if (err.name === 'AuthError') {
              setIsFailed(true)
            } else throw err
          }
        }}
        validationSchema={LoginSchema}
      >
        <Form.Row>
          <Form.Input autoFocus label='email' name='email' type='email' />
        </Form.Row>
        <Form.Row>
          <Form.Cell>
            <Form.Input label='password' name='password' type='password' />
            <c.ForgotPasswordLink>
              <e.Button.Text
                id='forgot'
                onClick={() => openAuthModal('forgot')}
                type='button'
              >
                forgotten your password?
              </e.Button.Text>
            </c.ForgotPasswordLink>
          </Form.Cell>
        </Form.Row>

        {isFailed && (
          <Form.Row>
            <Form.ErrorText>
              {`Your username or password is incorrect.
              Please try again or `}
              <e.Button.Text
                onClick={() => openAuthModal('forgot')}
                type='button'
              >
                reset your password
              </e.Button.Text>
              .
            </Form.ErrorText>
          </Form.Row>
        )}
        <e.Container.RowCenterJustified>
          <e.Button.default isBlock isCondensed type='submit' useSubmit>
            Next
          </e.Button.default>
        </e.Container.RowCenterJustified>
      </Form.Container>
    </e.Loading.Contained>
  )
}
