/* eslint-disable camelcase */
import * as Sentry from '@sentry/browser'
import axios, { AxiosError } from 'axios'

import config from '@fe/config'
import { uuid } from '@fe/utils/helpers'

const allplantsApi = axios.create({
  baseURL: config.coreDomain,
  timeout: config.apiTimeout,
})
allplantsApi.defaults.headers.common['X-CSRF-TOKEN'] = uuid

allplantsApi.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    const message = error.response?.data?.errors?.[0]?.message
    Sentry.addBreadcrumb({
      data: {
        errorMessage: error.message,
        status: error.response?.status,
        statusText: error.response?.statusText,
        response: JSON.stringify(error.response?.data || {}),
      },
      message: message || error.message,
      level: Sentry.Severity.Info,
    })
    return Promise.reject(error)
  }
)

export const register = async (userRegistrationData: {
  first_name: string
  last_name: string
  email: string
  password: string
}) =>
  allplantsApi.post('/auth/register', userRegistrationData, {
    withCredentials: true,
  })

export const login = async (userLoginData: {
  email: string
  password: string
}) =>
  allplantsApi.post('/auth/login', userLoginData, {
    withCredentials: true,
  })

export const forgotPassword = async (email) =>
  allplantsApi.post(
    '/auth/reset_password',
    { email },
    {
      withCredentials: true,
    }
  )

export const resetPassword = async (password, token) =>
  allplantsApi.post(
    `/auth/reset_password/${token}`,
    { password },
    {
      withCredentials: true,
    }
  )

export const logout = async () =>
  allplantsApi.post(
    '/auth/logout',
    {},
    {
      withCredentials: true,
    }
  )

export default allplantsApi
