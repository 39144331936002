import * as React from 'react'

export default function withHook<T>({
  useHook,
  name = null,
}: {
  useHook: (props: any) => T
  name?: string | null
}) {
  return (Component) => (outerProps) => {
    const data = useHook(outerProps)
    const props = name ? { [name]: data } : data
    return <Component {...outerProps} {...props} />
  }
}
