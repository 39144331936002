import * as safeAccess from 'safe-access'

import { useUserContext } from '@fe/providers/userProvider'
import * as allplantsAuth from '@fe/services/allplantsAuth'
import { trackEvent } from '@fe/services/analytics'

import AuthError from './authError'
import useAuthService from './useService'

export default function useLogin() {
  const { waitForSessionSet } = useAuthService()
  const { setUserDetails } = useUserContext()

  const login = async (userCredentials: {
    email: string
    password: string
  }) => {
    try {
      const loginResponse = await allplantsAuth.login(userCredentials)

      const { first_name, last_name, email } =
        loginResponse?.data?.data?.[0] || {}

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setUserDetails({
        firstName: first_name,
        lastName: last_name,
        email,
      })

      void trackEvent('auth.login.success')
      await waitForSessionSet()
    } catch (errResult) {
      const safeError = safeAccess(errResult)
      const errorInfo = {
        error: `${errResult}`,
        nestedError: JSON.stringify(safeAccess(errResult, 'response.data')),
      }
      switch (safeError('response.status')) {
        case 400: {
          void trackEvent('auth.login.fail', errorInfo)
          return Promise.reject(new AuthError('failed to login'))
        }
        default: {
          void trackEvent('auth.login.error', errorInfo)
          return Promise.reject(errResult)
        }
      }
    }
  }

  return {
    login,
  }
}
