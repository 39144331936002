import { useEffect } from 'react'

import { useRouter } from 'next/router'

import isRudderStackAvailable from '@fe/services/rudderstack/isRudderStackAvailable'

const usePageEvent = () => {
  const { asPath } = useRouter()
  useEffect(() => {
    if (isRudderStackAvailable()) {
      window.rudderanalytics!.page()
    }
  }, [asPath])
}

export default usePageEvent
