import * as React from 'react'

import * as c from './components'

export interface IModalProps {
  id?: string
  disableClose?: boolean
  hideBehind: boolean
  isOpen: boolean
  contentCss?: any
  containerCss?: any
  ModalOverlay?: typeof c.ModalOverlay
  onClose: () => void
  unmountOnClose: boolean
}
export default abstract class Modal<
  T = Record<string, unknown>,
  S = Record<string, unknown>
> extends React.Component<IModalProps & T, S> {
  public static defaultProps = {
    ModalOverlay: c.ModalOverlay,
    unmountOnClose: false,
    hideBehind: false,
  }

  protected tryClose = () => {
    const { onClose, disableClose } = this.props
    if (!disableClose) {
      onClose()
    }
  }

  protected abstract renderContent: () => JSX.Element

  public render = () => {
    const {
      id,
      isOpen,
      disableClose,
      ModalOverlay,
      contentCss,
      containerCss,
      unmountOnClose,
      hideBehind,
    } = this.props
    if (unmountOnClose && !isOpen) return null

    return (
      <c.Portal>
        <div>
          <ModalOverlay
            hideBehind={hideBehind}
            isOpen={isOpen}
            onClick={this.tryClose}
          >
            <c.ModalContentContainer
              css={containerCss}
              hideBehind={hideBehind}
              id={id}
              onClick={(e) => e.stopPropagation()}
            >
              {!disableClose && (
                <c.CloseButtonContainer onClick={this.tryClose}>
                  <c.Cross />
                </c.CloseButtonContainer>
              )}
              <c.ModalContent css={contentCss}>
                {this.renderContent()}
              </c.ModalContent>
            </c.ModalContentContainer>
          </ModalOverlay>
        </div>
      </c.Portal>
    )
  }
}
