import * as React from 'react'

import * as safeAccess from 'safe-access'

import * as allplantsAuth from '@fe/services/allplantsAuth'
import { trackEvent } from '@fe/services/analytics'

export default function useForgotPassword() {
  const [isLoading, setIsLoading] = React.useState(false)
  const [isFailed, setIsFailed] = React.useState(false)

  const forgot = async (email: string) => {
    try {
      setIsLoading(true)
      setIsFailed(false)
      await allplantsAuth.forgotPassword(email)
      void trackEvent('auth.forgot.success')
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      setIsFailed(true)
      void trackEvent('auth.forgot.fail', {
        error: `${e}`,
        nestedError: JSON.stringify(safeAccess(e, 'response.data')),
      })
      return Promise.reject(e)
    }
  }

  return {
    isLoading,
    isFailed,
    setIsFailed,
    forgot,
  }
}
