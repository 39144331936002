export const trim = (s, c) => {
  if (c && c.length > 5)
    throw Error('Preventing long character trimming for security')
  let toTrim = c
  if (toTrim === ']') toTrim = '\\]'
  if (toTrim === '\\') toTrim = '\\\\'
  return s.replace(new RegExp(`^[${toTrim}]+|[${toTrim}]+$`, 'g'), '')
}

export const commaList = (list?: Array<string>, allowEmpty = false) => {
  if (!list || list.length === 0) {
    if (allowEmpty) return ''
    throw new Error('Empty is not allowed')
  }
  if (list.length === 1) return list[0]
  const lastItem = list.pop()
  return `${list.join(', ')} and ${lastItem}`
}
