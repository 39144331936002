import { Portal as ReactPortal } from 'react-portal'

import * as e from '@fe/components/Elements'
import styled, { css } from '@fe/styles'

export const Portal = styled(ReactPortal)``
export const ModalOverlay = styled.div<{
  hideBehind: boolean
  isOpen: boolean
  onClick: () => void
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${(p) => p.theme.depth.front};
  background: ${(p) => p.theme.color.overlay};
  cursor: pointer;
  transition: ${(p) => p.theme.transition.default};
  opacity: 0;
  pointer-events: none;
  overflow-y: auto;
  max-height: 100vh;
  padding: 40px;
  ${(p) =>
    p.hideBehind &&
    css`
      background: white;
      cursor: default;
    `}
  ${(p) =>
    p.isOpen &&
    css`
      opacity: 1;
      pointer-events: auto;
    `}
`
export const ModalContentContainer = styled.div<{
  hideBehind: boolean
  css: any
}>`
  position: relative;
  background: white;
  border: 3px solid black;
  padding: 0 15px;
  margin: auto;
  width: 100%;
  max-width: 540px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${(p) => p.theme.font.allplants};
  cursor: default;

  ${(p) =>
    p.hideBehind &&
    css`
      border-color: transparent;
    `}
  }
  ${(p) => p.theme.media.desktop`
    padding: 0 64px;
  `}
  ${(p) => p.css}
`
export const ModalContent = styled.div<{
  css: any
}>`
  width: 100%;
  text-align: center;
  padding: 20px 0;
  ${(p) => p.css}
`
export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  z-index: 1;
`
export const Cross = styled(e.Icon.Cross)`
  width: 15px;
  height: 15px;
  display: block;
  margin: 0 auto;
`

export const Header = styled(e.Text.Regular)`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
`
export const Paragraph = styled(e.Text.Paragraph)`
  font-size: 20px;
  margin: 0 0 12px;
`
export const Spacer = styled.hr`
  opacity: 0;
  margin: 0;
  padding: 0;
  height: 12px;
  border: none;
`
