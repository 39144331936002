import { string } from 'yup'

const emailValidation = string()
  .strict(true)
  .trim('please remove any spaces')
  .email('please enter a valid email address')
  .required('please enter your email address')
  .ensure()

export default emailValidation
