import * as mainElements from '@fe/components/Elements'
import styled, { css } from '@fe/styles'

export const HeaderContainer = styled(
  mainElements.Container.RowCenterJustified
)`
  position: relative;
  z-index: ${(p) => p.theme.depth.foreground};
  background-color: ${(p) => p.theme.color.page};
  box-shadow: ${(p) => p.theme.other.boxShadow};
`

export const LogoContainer = styled.div<{
  disabled?: boolean
}>`
  max-width: ${(p) => p.theme.layout.maxWidth};
  margin: 0 auto;
  padding: 20px 0;
  text-align: center;
  background-color: ${(p) => p.theme.color.page};
  cursor: pointer;
  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
    `}
`
