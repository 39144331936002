import * as React from 'react'

import Modal from '@fe/components/common/Modal/basic'

import useAuthService from '../../../hooks/Auth/useService'

import ForgotForm from './forgot'
import LoginForm from './login'
import ResetPassword from './resetPassword'

export default function AuthModal() {
  const { authModal, openAuthModal } = useAuthService()
  const isOpen = ['login', 'forgot', 'reset'].includes(authModal)

  const getForm = React.useCallback(
    (openForm) => {
      switch (openForm) {
        case 'login':
          return <LoginForm setOpenForm={openAuthModal} />
        case 'forgot':
          return <ForgotForm setOpenForm={openAuthModal} />
        case 'reset':
          return <ResetPassword setOpenForm={openAuthModal} />
        default:
          return null
      }
    },
    [openAuthModal]
  )

  return (
    <Modal isOpen={isOpen} onClose={() => openAuthModal()}>
      <div>{getForm(authModal)}</div>
    </Modal>
  )
}
