/* eslint-disable camelcase */
import config from '@fe/config'
import { trim } from '@fe/utils/stringManipulation'

const flowerUrl = trim(config.shopDomain, '/')
const peanutUrl = trim(config.accountDomain, '/')

export const createLinkToFlower = (relativeUrl?: string) =>
  `${flowerUrl}${relativeUrl || ''}`
export const createLinkToPeanut = (relativeUrl?: string) =>
  `${peanutUrl}${relativeUrl || ''}`

export const homepageUrl: string = flowerUrl
export const menuUrl: string = createLinkToFlower('/shop')
export const contactUrl: string = createLinkToFlower('/contact')
export const accountUrl: string = createLinkToPeanut()
export const impactUrl: string = createLinkToPeanut('/impact')

export const goTo = (url: string, target?: string) => {
  if (typeof window === 'undefined') return
  if (target) window.open(url, target)
  else window.location.href = url
}
export const goToFlower = (relativeUrl?: string) => {
  goTo(createLinkToFlower(relativeUrl))
}
export const goToShop = () => {
  goToFlower('/shop')
}

export const goToBasket = () => {
  goToFlower('/shop')
}
export const goToAccount = () => {
  goTo(config.accountDomain!)
}
export const goToReferral = () => {
  // Always use production shopify as the page doesn't exist in staging
  goTo(
    'https://shop.allplants.com/pages/referral-landing?situation=homepagebanner',
    '_blank'
  )
}

export const goToHome = () => {
  goToFlower()
}

export default goTo
