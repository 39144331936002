import * as React from 'react'

import { useRouter } from 'next/router'
import * as Yup from 'yup'

import * as e from '@fe/components/Elements'
import * as Form from '@fe/components/Form'
import useResetPassword from '@fe/hooks/Auth/useResetPassword'

import useAuthService from '../../../hooks/Auth/useService'

import * as c from './components'

const PasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'please enter more than 8 characters')
    .matches(/\d/, 'please include at least 1 number')
    .matches(/[a-zA-Z]/, 'please include at least 1 letter')
    .required('please enter a password')
    .ensure(),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password')], 'Both password fields must match')
    .required('please enter your confirmed password'),
})

export default function ResetPassword() {
  const { isLoading, isFailed, setIsFailed, reset } = useResetPassword()

  const { openAuthModal } = useAuthService()

  const { query } = useRouter()

  const getContentToDisplay = React.useCallback(() => {
    if (isFailed) return 'reset_failed'
    if (!query.token) return 'no_token'
    if (!query.email) return 'no_email'
  }, [isFailed, query])

  const [contentToDisplay, setContentToDisplay] = React.useState(
    getContentToDisplay()
  )
  React.useEffect(() => {
    setContentToDisplay(getContentToDisplay())
  }, [setContentToDisplay, getContentToDisplay])

  const renderContent = React.useCallback(() => {
    switch (contentToDisplay) {
      case 'success':
        return <c.SuccessMessage>Password successfully reset!</c.SuccessMessage>
      case 'no_token':
        return (
          <e.Text.PSmall>
            No reset password token supplied - please request a reset password
            link from{' '}
            <e.Button.Basic onClick={openAuthModal('forgot')}>
              here
            </e.Button.Basic>
          </e.Text.PSmall>
        )
      case 'no_email':
        return (
          <e.Text.PSmall>
            No email supplied to reset password - please request a reset
            password link from{' '}
            <e.Button.Basic onClick={openAuthModal('forgot')}>
              here
            </e.Button.Basic>
          </e.Text.PSmall>
        )
      case 'reset_failed':
        return (
          <e.Text.PSmall>
            There has been an error resetting your password - request a new
            reset password link from{' '}
            <e.Button.Basic onClick={openAuthModal('forgot')}>
              here
            </e.Button.Basic>{' '}
            or{' '}
            <e.Button.Text
              onClick={() => {
                setIsFailed(false)
                setContentToDisplay('')
              }}
            >
              try again
            </e.Button.Text>
          </e.Text.PSmall>
        )
      default:
        return (
          <Form.Container
            initialValues={{}}
            name='reset'
            onSubmit={async ({ password }) => {
              await reset(password, query.token)
              setContentToDisplay('success')
            }}
            validationSchema={PasswordSchema}
          >
            {() => (
              <>
                <Form.Row>
                  <Form.Input
                    autoFocus
                    label='password'
                    name='password'
                    type='password'
                  />
                </Form.Row>
                <Form.Row>
                  <Form.Input
                    label='confirm password'
                    name='passwordConfirm'
                    type='password'
                  />
                </Form.Row>
                <e.Container.RowCenterJustified>
                  <e.Button.default isBlock isCondensed type='submit' useSubmit>
                    Reset Your Password
                  </e.Button.default>
                </e.Container.RowCenterJustified>
              </>
            )}
          </Form.Container>
        )
    }
  }, [contentToDisplay, openAuthModal, query.token, reset, setIsFailed])

  return (
    <e.Loading.Contained isLoading={isLoading} message='sending you an email'>
      <c.ResetPageIntro>
        <e.Text.FormTitleText>
          <strong>
            {!contentToDisplay && `change the password for ${query.email}`}
          </strong>
        </e.Text.FormTitleText>
      </c.ResetPageIntro>
      {renderContent()}
    </e.Loading.Contained>
  )
}
