import * as e from '@fe/components/Elements'
import styled from '@fe/styles'

export const AppContainer = styled.div`
  background: ${(p) => p.theme.color.page};
  color: ${(p) => p.theme.color.text};
  font-family: ${(p) => p.theme.font.sans};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

export const Content = styled.div`
  flex: 1 0 auto;
  position: relative;
`

export const DisruptionBannerContainer = styled.div`
  width: 100%;
  font-weight: 500;
  background: black;
  color: white;
  ${(p) => p.theme.layout.paddingResizeCss};
  display: flex;
  flex-direction: row;
  justify-content: center;
`
export const DisruptionBannerContent = styled.div`
  max-width: ${(p) => p.theme.layout.smallMaxWidth};
`
export const DisruptionBannerAnchor = styled.a`
  color: white;
`
export const SiteDownIcon = styled(e.Icon.SiteDown)`
  margin-right: 12px;
  width: 40px;
  margin-top: 4px;
  ${(p) => p.theme.media.tablet`
    margin-top: 0;
    height: 100%;
    width: 40px;
    align-self: center;
  `}
`
