import * as React from 'react'

import { useRouter } from 'next/router'
import { toast } from 'react-hot-toast'
import { compose } from 'recompose'

import Toast from '@fe/components/Elements/toast'
import useTryApplyDiscount from '@fe/components/checkout/DiscountInput/useTryDiscount'
import AuthModals from '@fe/components/common/Modals'
import withHook from '@fe/components/withHook'
import config from '@fe/config'
import useCart from '@fe/providers/cart'
import useFeatureDetection from '@fe/providers/featureDetection'
import usePageEvent from '@fe/services/rudderstack/usePageEvent'
import { contactUrl } from '@fe/utils/redirect'

import Header from './Header'
import * as c from './error'

interface IProps {
  component: any
  disableRedirectToHome?: boolean
  isPaymentPage?: boolean
}

const Page: React.FC<IProps> = ({
  component: Component,
  disableRedirectToHome,
  isPaymentPage,
}) => {
  const { query } = useRouter()
  const { cart, getCart } = useCart()
  const { tryApplyDiscount } = useTryApplyDiscount()

  const reapplyDiscountIfExists = async () => {
    if (typeof query.discount === 'string' && cart?.token && cart?.id) {
      try {
        // Reapply the same discount to our cart so that we can check that the
        // discount code is valid against this user.
        await tryApplyDiscount(cart.token, query.discount, cart.id)
      } catch (err) {
        await getCart(cart.token) // Get the latest cart (updating the UI)
        const errorType = err.response?.data?.errors?.[0]?.error_code
        if (errorType === 'discount_new_customers_only') {
          toast.custom(
            <Toast
              message='Sorry, the code won’t work with this order.'
              type='error'
            />,
            {
              duration: Infinity,
            }
          )
        }
      }
    }
  }

  React.useEffect(() => {
    reapplyDiscountIfExists()
  }, [query, cart?.id, cart?.token])

  usePageEvent()
  return (
    <>
      <c.AppContainer>
        {config.disruptionBanner ? (
          <c.DisruptionBannerContainer>
            <c.SiteDownIcon />
            <c.DisruptionBannerContent>
              {config.disruptionBanner}
              <br />
              {'If you need help, you can always contact us at '}
              <c.DisruptionBannerAnchor href={contactUrl} target='_blank'>
                here
              </c.DisruptionBannerAnchor>
              , and we will be back shortly!
            </c.DisruptionBannerContent>
          </c.DisruptionBannerContainer>
        ) : null}
        <Header disableRedirectToHome={disableRedirectToHome} />
        <c.Content>
          <div>
            <Component />
          </div>
        </c.Content>
      </c.AppContainer>
      <AuthModals />
      {isPaymentPage && (
        <>
          <script async id='stripe-js' src='https://js.stripe.com/v3/' />
        </>
      )}
    </>
  )
}

export default compose(
  withHook({
    name: 'featureDetection',
    useHook: useFeatureDetection,
  })
)(Page) as unknown as React.FC<Omit<IProps, 'featureDetection'>>
