import * as React from 'react'

import * as e from '@fe/components/Elements'
import { goToHome } from '@fe/utils/redirect'

import * as c from './components'

const Header: React.FC<{
  disableRedirectToHome?: boolean
}> = ({ disableRedirectToHome }) => (
  <c.HeaderContainer>
    <c.LogoContainer
      disabled={disableRedirectToHome}
      onClick={() => goToHome()}
    >
      <e.Logo.FullLogo />
    </c.LogoContainer>
  </c.HeaderContainer>
)
export default Header
